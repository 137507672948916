<template>
    <v-app :style="colorVapp">
    <onebox_admin_toolbar
      :quicksearch="true"
      @loaddata="loaddata()"
      @loadfile="loadfolder()"
      @callstorage="loadstorage()"
      @closeDrag="removeEvent()"
      @openDrag="addEvent()"
    ></onebox_admin_toolbar>
    <v-content>
        <v-card class="elevation-0">
            <v-divider></v-divider>
        <v-overlay :value="processloader" absolute :color="color.BG" dark>
          <v-progress-circular indeterminate size="64" :color="color.theme"></v-progress-circular>
          <span :style="colorProgress">&nbsp; loading</span>
        </v-overlay>
        
        <!-- hearder -->
        <v-list nav :color="color.BG">
          <v-list-item class="text-left">
            <v-list-item-avatar class="text-center">
              <v-avatar :color="color.theme" size="35">
                <v-icon dark size="20">mdi-file-table-box-multiple</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="pa-1">
                <span
                  :style="headerPage" 
                >&nbsp;{{$t('admin.log')}}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card class="elevation-0" :color="color.BG" id="thiscontainerlogadmin">
             <v-card-text class="pa-0">
                <v-container fluid>
                                  <v-expansion-panels v-model="panel" multiple outlined>
                  <v-expansion-panel class="pa-1">
                    <v-expansion-panel-header>
                      <span :style="headerPage">{{$t('adminlog.fine')}}</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pa-1">
                      <v-form>
                        <v-layout row wrap fill-height justify-center>
                          <v-flex xs12 sm12 md5 lg5 class="pa-1">
                            <v-container fluid>
                              <v-layout row wrap justify-center>
                                <!-- ช่องกรอกชื่อไฟล์ -->
                                
                                <!-- ช่องเลือกประเภทไฟล์ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-select
                                    outlined
                                    dense
                                    
                                    class="pa-1 pt-0 pb-0"
                                    v-model="selectdata"
                                    :items="$t('default') === 'en' ? key_data_eng : key_data"
                                    item-value="key"
                                    item-text="name"
                                    :label="$t('adminlog.log_information')"
                                    prepend-icon="mdi-file-table"
                                    :color="color.theme"
                                    :item-color="color.theme"
                                    @change="getdataprocess()"
                                  ></v-select>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-select
                                    outlined
                                    dense
                                    
                                    class="pa-1 pt-0 pb-0"
                                    v-model="selectprocess"
                                    :items="dataprocessname"
                                    item-value="process_id"
                                    item-text="process_name"
                                    label=""
                                    prepend-icon="mdi-file-table"
                                    :color="color.theme"
                                    :item-color="color.theme"
                                    :disabled="selectdata === ''"
                                  ></v-select>
                                </v-flex>
                              </v-layout>
                              <v-layout row wrap justify-center>
                                <!-- ช่องวันที่ได้รับไฟล์ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-dialog
                                    ref="dialogFromdateupload"
                                    v-model="modalFromdateupload"
                                    :return-value.sync="fromdateupload"
                                    persistent
                                    full-width
                                    width="290px"
                                    :color="color.theme"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        clearable
                                        :color="color.theme"
                                        :label="$t('myinboxPage.conditionOptional.fromdateupload')"
                                        v-model="fromdateupload"
                                        @click:prepend="modalFromdateupload = true"
                                        prepend-icon="event"
                                        v-on="on"
                                        readonly
                                        outlined
                                        dense
                                        :rules="dateRules"
                                        @click:clear="todateupload = ''"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="fromdateupload"
                                      scrollable
                                      :locale="$t('default')"
                                      @input="changeFromdateupload()"
                                      :color="color.theme"
                                      :max="date_now"
                                      :min="date_back"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        dark
                                        color="error"
                                        @click.stop="modalFromdateupload = false"
                                      >{{$t('myinboxPage.conditionOptional.closefromdateupload')}}</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </v-flex>
                                <!-- ช่องวันที่ได้รับไฟล์ -->
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-dialog
                                    ref="dialogTodateupload"
                                    v-model="modalTodateupload"
                                    :return-value.sync="todateupload"
                                    persistent
                                    full-width
                                    width="290px"
                                    :color="color.theme"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        :disabled="!fromdateupload"
                                        clearable
                                        readonly
                                        :color="color.theme"
                                        :label="$t('myinboxPage.conditionOptional.todateupload')"
                                        v-model="todateupload"
                                        prepend-icon="event"
                                        @click:prepend="modalTodateupload = true"
                                        v-on="on"
                                        :rules="dateRules"
                                        outlined
                                        dense
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="todateupload"
                                      scrollable
                                      :locale="$t('default')"
                                      @input="$refs.dialogTodateupload.save(todateupload)"
                                      :max="date_now"
                                      :min="fromdateupload"
                                      :color="color.theme"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        dark
                                        color="error"
                                        @click.stop="modalTodateupload = false"
                                      >{{$t('myinboxPage.conditionOptional.closetodateupload')}}</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </v-flex>
                              </v-layout>
                              <v-layout row wrap justify-center>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    outlined
                                    dense
                                    clearable
                                    class="pa-1 pt-0 pb-0"
                                    v-model="logname"
                                    :label="$t('adminlog.employee_name')"
                                    prepend-icon="account_box"
                                    :color="color.theme"
                                  ></v-text-field>
                                 
                                </v-flex>
                                 <v-flex xs12 sm12 md6 lg6>
                                 <v-text-field
                                    outlined
                                    dense
                                    clearable
                                    class="pa-1 pt-0 pb-0"
                                    v-model="logfilename"
                                    :label="$t('adminlog.file_folder_name')"
                                    prepend-icon="mdi-file"
                                    :color="color.theme"
                                    :disabled="check_filemane"
                                  ></v-text-field>
                                 </v-flex>
                                <!-- <v-flex xs12 sm12 md6 lg6> -->
                                  <!-- ช่องเลือก ประเภเอกสาร -->

                                  <!-- <v-select
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="documenttype"
                                    :items="listDoctype"
                                    item-value="document_type"
                                    item-text="document_type"
                                    :label="$t('myinboxPage.conditionOptional.doctype')"
                                    prepend-icon="line_style"
                                    :color="color.theme"
                                    :item-color="color.theme"
                                  ></v-select>
                                </v-flex> -->
                              </v-layout>
                              <!-- ช่องกรอก ผู้รับ -->
                              <v-layout row wrap justify-center>
                                <!-- <v-flex xs12 sm12 md12 lg12>
                                  <v-text-field
                                    outlined
                                    dense
                                    clearable
                                    class="pa-1 pt-0 pb-0"
                                    v-model="receivername"
                                    :label="$t('myinboxPage.conditionOptional.receivername')"
                                    prepend-icon="account_circle"
                                    :color="color.theme"
                                  ></v-text-field>
                                </v-flex> -->
                                <!-- chanel -->
                                <!-- <v-flex xs12 sm12 md6 lg6>
                                  <v-select
                                    clearable
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="chanel"
                                    :items="listDoctype"
                                    item-value="document_type"
                                    item-text="document_type"
                                    :label="$t('myinboxPage.conditionOptional.chanel')"
                                    prepend-icon="reorder"
                                    :color="color.theme"
                                    :item-color="color.theme"
                                  ></v-select>
                                </v-flex> -->
                              </v-layout>
                            </v-container>
                          </v-flex>

                          <!-- <v-flex xs12 sm12 md1 lg1 class="text-center">
                            <v-divider vertical></v-divider>
                          </v-flex> -->

                          <!-- <v-flex xs12 sm12 md5 lg5 class="pa-1 text-center">
                            <v-container fluid>
                              <v-layout row wrap justify-center>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="documentid"
                                    label=""
                                    prepend-icon="format_list_numbered"
                                    :color="color.theme"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="documentidto"
                                    :label="$t('myinboxPage.conditionOptional.todateupload')"
                                    prepend-icon="format_list_numbered"
                                    :color="color.theme"
                                  ></v-text-field>
                                </v-flex>
                              </v-layout> -->
                              <!-- <v-layout row wrap justify-center> -->
                                <!-- ช่องกรอก เอกสารลงวันที่ -->
                                <!-- <v-flex xs12 sm12 md6 lg6>
                                  <v-dialog
                                    ref="dialogFromdatedocument"
                                    v-model="modalFromdatedocument"
                                    :return-value.sync="fromdatedocument"
                                    persistent
                                    full-width
                                    width="290px"
                                    :color="color.theme"
                                  > -->
                                    <!-- <template v-slot:activator="{ on }">
                                      <v-text-field
                                        :color="color.theme"
                                        :label="$t('myinboxPage.conditionOptional.fromdatedocument')"
                                        v-model="fromdatedocument"
                                        @click:prepend="modalFromdatedocument = true"
                                        prepend-icon="event"
                                        v-on="on"
                                        readonly
                                        outlined
                                        dense
                                        clearable
                                        :persistent-hint="fromdate_hint"
                                        @click:clear="todatedocument = ''"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="fromdatedocument"
                                      scrollable
                                      :locale="$t('default')"
                                      @input="changeFromdatedocument()"
                                      :color="color.theme"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        dark
                                        color="error"
                                        @click.stop="modalFromdatedocument = false"
                                      >{{$t('myinboxPage.conditionOptional.closefromdatedocument')}}</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </v-flex> -->

                                <!-- ช่องกรอก เอกสารลงวันที่ -->
                                <!-- <v-flex xs12 sm12 md6 lg6>
                                  <v-dialog
                                    ref="dialogTodatedocument"
                                    v-model="modalTodatedocument"
                                    :return-value.sync="todatedocument"
                                    persistent
                                    full-width
                                    width="290px"
                                    :color="color.theme"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        readonly
                                        :color="color.theme"
                                        :label="$t('myinboxPage.conditionOptional.todatedocument')"
                                        v-model="todatedocument"
                                        prepend-icon="event"
                                        @click:prepend="modalTodatedocument = true"
                                        v-on="on"
                                        outlined
                                        dense
                                        clearable
                                        :persistent-hint="todate_hint"
                                        class="my-hint-style pa-1 pt-0 pb-0"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      v-model="todatedocument"
                                      scrollable
                                      :locale="$t('default')"
                                      @input="$refs.dialogTodatedocument.save(todatedocument)"
                                      :min="fromdatedocument"
                                      :color="color.theme"
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        dark
                                        color="error"
                                        @click.stop="modalTodatedocument = false"
                                      >{{$t('myinboxPage.conditionOptional.closetodatedocument')}}</v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                </v-flex>
                              </v-layout> -->

                              <!-- ช่องกรอก SellerTaxID -->
                              <!-- <v-layout row wrap justify-center>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="sellertaxid"
                                    :label="$t('myinboxPage.conditionOptional.sellertaxid')"
                                    prepend-icon="mdi-domain"
                                    :color="color.theme"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="sellerbranchid"
                                    :label="$t('myinboxPage.conditionOptional.sellerbranchid')"
                                    prepend-icon="mdi-format-list-bulleted-square"
                                    :color="color.theme"
                                  ></v-text-field>
                                </v-flex>
                              </v-layout> -->

                              <!-- ช่องกรอก BuyerTaxID -->
                              <!-- <v-layout row wrap justify-center>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="buyertaxid"
                                    :label="$t('myinboxPage.conditionOptional.buyertaxid')"
                                    prepend-icon="mdi-domain"
                                    :color="color.theme"
                                  ></v-text-field>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                  <v-text-field
                                    outlined
                                    dense
                                    class="pa-1 pt-0 pb-0"
                                    v-model="buyerbranchid"
                                    :label="$t('myinboxPage.conditionOptional.buyerbranchid')"
                                    prepend-icon="mdi-format-list-bulleted-square"
                                    :color="color.theme"
                                  ></v-text-field>
                                </v-flex>
                              </v-layout> -->
                            <!-- </v-container>
                          </v-flex> -->
                        </v-layout>
                      </v-form>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-flex xs12 lg12 class="text-center">
                <br />
                <v-btn
                  class="ma-1 white--text"
                  :color="color.theme"
                  :disabled="loaddataprogress"
                  :loading="loaddataprogress"
                  @click="getprocess()"
                >
                  <v-icon left>search</v-icon>
                  &nbsp;
                  {{ $t('myinboxPage.search')}}
                </v-btn>
                <v-btn
                 outlined
                  
                  :color="color.theme"
                  :disabled="loaddataprogress"
                  :loading="loaddataprogress"
                  @click="downloaddocument()"
                >
                  <v-icon left small>mdi-arrow-collapse-down</v-icon>
                  &nbsp;{{ $t('adminlog.download')}}
                </v-btn>
              </v-flex>
              <v-layout row wrap justify-center fill-height>
                <v-flex lg12 class="ma-2 ml-4 mr-4" v-if="loaddataprogress === true">
                  <div class="text-center">
                    <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                  </div>
                </v-flex>
                <v-flex lg12 class="ma-4" v-else>
                  <v-layout row wrap class="pl-3 pr-3" >
                    <v-flex lg2 xs12 :color="color.theme" class="pt-1">
                      <v-select
                        :color="color.theme"
                        prepend-inner-icon="sort"
                        hide-details
                        outlined
                        solo
                        dense
                        v-model="size"
                        :items="listsize"
                        class="text-center"
                        :item-color="color.theme"
                        @change="clickpagination()"
                      >
                        <template v-slot:item="{ item }" :color="color.theme">
                          <div class="text-center">
                            <span style="font-size: 17px;">{{ item }}</span>
                          </div>
                        </template>
                        <template v-slot:selection="{ item }" :color="color.theme">
                          <div class="text-center">
                            <span style="font-size: 17px;">{{ item }}</span>
                          </div>
                        </template>
                      </v-select>
                    </v-flex> 
                      <!-- <v-flex lg2 xs12 class="pl-1 pt-1">
                        <v-select                           
                            label=""
                            solo
                            dense
                            :color="color.theme"
                            :item-color="color.theme"
                            :items="key_data"
                            v-model="selectdata"
                            @change="getdataprocess()"
                        ></v-select>
                      </v-flex> -->
                      <!-- <v-flex lg2 xs12 class="pl-1 pt-1" v-if="selectdata !== ''" >
                        <v-select  
                                                    
                            label=""
                            solo
                            dense
                            :color="color.theme"
                            :item-color="color.theme"
                            :items="dataprocessname"
                            item-text="process_name"
                            item-value="process_id"
                            v-model="selectprocess"
                            @change="getprocess()"
                        ></v-select>                       
                      </v-flex> -->
                      <!-- <v-flex lg6 class="pl-1 pt-2" v-if="selectdata === '' && resolutionScreen > 500" >
                      <span  style="color:red;"><v-icon style="color:red;">mdi-arrow-left-thick</v-icon>{{$t('adminlog.text')}}</span>
                      </v-flex>
                      <v-flex lg6 class="pl-1" v-if="selectdata === '' && resolutionScreen <= 500" >
                      <span  style="color:red;"><v-icon style="color:red;">mdi-arrow-up-thick</v-icon>{{$t('adminlog.text')}}</span>
                      </v-flex> -->
                      <!-- <v-flex lg2 v-if="selectdata === ''">
                      <v-btn @click="opendownloadlog = true"><v-icon >mdi-download</v-icon>Download</v-btn>
                      </v-flex>
                      <v-flex offset-lg4 lg2 v-else>
                      <v-btn @click="opendownloadlog = true"><v-icon >mdi-download</v-icon>Download</v-btn>
                      </v-flex> -->
                    </v-layout>
                     <!-- Phonee -->
                      <div v-if="loaddataprogresstable === true ">
                        <v-flex lg12 class="ma-2 ml-4 mr-4" >
                  <div class="text-center">
                    <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                  </div>
                </v-flex></div>
                <div v-else>
                    <div v-if="resolutionScreen <= 500">
                      <v-list two-line class="mb-6 pb-6 elevation-0" v-if="this.countdata !== 0" >
                        <v-list-item
                        v-for="item in dataprocesslog"
                        :key="item.title"
                        style="cursor: pointer;"
                        >
                        <v-list-item-content>
                        <v-list-item-title v-if="$t('default') === 'th'" v-text="item.name_th"></v-list-item-title>
                        <v-list-item-title v-else v-text="item.name_en"></v-list-item-title>
                        <v-list-item-subtitle v-if="$t('default') === 'th'" v-text="item.process_name"></v-list-item-subtitle>
                        <v-list-item-subtitle v-else v-text="item.process_name_eng"></v-list-item-subtitle>
                        <v-list-item-subtitle v-text="formatdatetime(item.process_dtm)"></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn class="elevation-0" fab small @click="opendetaillog = true , detaildata = item"><v-icon >mdi-information</v-icon></v-btn>
                        </v-list-item-action>
                        </v-list-item>
                      </v-list>
                       <v-list v-else class="mb-6 pb-6 elevation-0">
                     <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="text-center" v-text="$t('tablefile.empty')"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list> 
                    </div>
                     <!-- Labtop -->
                     <div v-else>  
                      <v-data-table
                        :headers="($t('default') === 'en')? headerEN:headerTH"  
                        :items="dataprocesslog"               
                        class="elevation-0" 
                        :no-data-text="$t('tablefile.empty')"  
                        :page.sync="page"
                        :items-per-page="size"              
                        :hide-default-footer="true"
                        @page-count="pageCount = $event"
                      >
                      <template  v-slot:[`header.type`]="{ header }">
                        <span 
                          class="pointer"
                          
                          :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <template v-if="$t('default') === 'th'"  v-slot:[`header.name_th`]="{ header }">
                        <span 
                          class="pointer"
                          
                          :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <template v-else  v-slot:[`header.name_en`]="{ header }">
                        <span 
                          class="pointer"
                          
                          :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <template  v-slot:[`header.current_filename`]="{ header }">
                        <span 
                          class="pointer"
                          
                          :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <template  v-slot:[`header.process_name`]="{ header }">
                        <span 
                          class="pointer"
                          
                          :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <template  v-slot:[`header.process_dtm`]="{ header }">
                        <span 
                          class="pointer"
                          
                          :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template>
                      <template  v-slot:[`header.detail`]="{ header }">
                        <span 
                          class="pointer"
                          
                          :style="headerTable"
                        >{{ $t(header.text) }}</span>
                      </template>

                       <template v-slot:item="props">
                        <!-- {{bufferprocess}} -->
                        <tr>
                          <td width="7%" class="text-center"  ><v-icon>mdi-account</v-icon></td>
                          <td width="25%"  v-if="$t('default') === 'th'">{{ props.item.name_th}}</td>
                          <td width="25%"  v-else>{{ props.item.name_en}}</td>
                          <td width="25%" >{{props.item.current || '-'}}</td>
                          <td width="15%" v-if="$t('default') === 'th'" >{{ props.item.process_name}}</td>
                          <td width="15%" v-else>{{ props.item.process_name_eng}}</td>
                          <td width="25%" class="text-center" >{{formatdatetime(props.item.process_dtm)}}</td>
                          <td width="8%" class="text-center"><v-btn class="elevation-0" fab small @click="opendetaillog = true , detaildata = props.item"><v-icon >mdi-information</v-icon></v-btn></td>
                        </tr>
                       </template>
                      </v-data-table>
                     </div>
                      <br>
                    <!-- <v-pagination
                      v-model="page"
                      :length="pageCount"
                      :color="color.theme"
                      :total-visible="10"
                      @input="clickpagination()"
                    ></v-pagination> -->
                    <v-layout row wrap justify-center>
                    <v-flex xs11 lg11 v-if="pageCount > 0">
                      <v-pagination
                        :total-visible="10"
                        v-model="page"
                        :length="pageCount || 0"
                        :color="color.theme"
                        @input="clickpagination()"
                      ></v-pagination>
                    </v-flex>
                  </v-layout>
                    </div>
                </v-flex>
                
              </v-layout>
                
                </v-container>
                <detaillog
                  :show="opendetaillog"  
                  :detaildata="detaildata"
                  @closedetaillog="opendetaillog=false"
                ></detaillog>
                 <downloadlog
                  :show="opendownloadlog"  
                  :process_type_id="data_process.process_type_id"
                  :process_id="selectprocess"
                  :selectdata="selectdata"
                  :selectprocess="selectprocessname"
                  @close="opendownloadlog=false"
                ></downloadlog>
             </v-card-text>
        </v-card>
        </v-card>
    </v-content>
    </v-app>
</template>
<script>
import { saveAs } from "file-saver";
import format from "date-fns/format";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import InfiniteLoading from "vue-infinite-loading";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import VueFullScreenFileDrop from "vue-full-screen-file-drop";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import gbfdecodearraybuffer from "@/globalFunctions/decodearraybuffer";
import onebox_admin_toolbar from "../components/layout/layout-admin-toolbar";
const downloadlog = () => import("../components/optional/dialog-downloadlog");
const detaillog = () => import("../components/optional/dialog-detaillog");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});
export default {
    components:{
     onebox_admin_toolbar,
     detaillog,
     downloadlog
    }, 
    data: function() {
    return {
      date_now_noformat: "",
      date_back: "",
      date_now:"",
      check_filemane:true,
      logfilename:"",
      panel:false,
      fromdateupload: "",
      modalFromdateupload: false,
      todateupload: "",
      modalTodateupload: false,
      logname:"",
      loaddataprogresstable:false,
      countdata:0,
      page: 1,
      detaildata:{},
      loaddataprogress:true,
      opendetaillog:false,
      opendownloadlog:false,
      selectdata:"",
      selectprocess:"",
      key_data:[],
      key_data_eng:[
        {
          name:"Log in",
          key:"ล็อกอิน",
        },
         {
          name:"Shared file, Shared folder",
          key:"แชร์ไฟล์, แชร์โฟลเดอร์",
        },
         {
          name:"Setting permission , Setting quota storage",
          key:"การตั้งค่าสิทธิ์, การตั้งค่าความจุ",
        },
         {
          name:"File",
          key:"จัดการไฟล์",
        },
         {
          name:"inbox,outbox",
          key:"อินบ็อกซ์, เอาท์บ็อกซ์",
        },
         {
          name:"Folder",
          key:"จัดการโฟลเดอร์",
        },
      ],
      dataprocess:[],
      data_process:[],
      bufferprocess:{},
      dataprocessname:[],
      selectprocessname:"",
      dataprocesslog:[],
      size: 20,
      offset:0,
      page: 1,
      x: 0,
      y: 0,
      listsize: [10,20, 50, 100],
    headerTH:[
      {
          text: "#",
          align: "center",
          value: "type",
          width: "7%",
          sortable: false,
        },
      {
        text: "adminlog.name",
        align: "",
        value: "name_th",
        width: "25%",
        sortable: false
      },
      {
        text: "adminlog.file_folder_name",
        align: "",
        value: "current_filename",
        width: "25%",
        sortable: false
      },
      {
        text: "adminlog.process_name",
        align: "",
        value: "process_name",
        width: "15%",
        sortable: false
      },
      {
        text: "adminlog.process_dtm",
        align: "center",
        value: "process_dtm",
        width: "25%",
        sortable: false
      },
      {
        text: "adminlog.detail",
        align: "center",
        value: "detail",
        width: "8%",
        sortable: false
      }
      ],
      headerEN:[
         {
          text: "#",
          align: "center",
          value: "type",
          width: "7%",
          sortable: false,
        },
      {
        text: "adminlog.name",
        align: "",
        value: "name_en",
        width: "25%",
        sortable: false
      },
      {
        text: "adminlog.file_folder_name",
        align: "",
        value: "current_filename",
        width: "25%",
        sortable: false
      },
      {
        text: "adminlog.process_name",
        align: "",
        value: "process_name",
        width: "15%",
        sortable: false
      },
      {
        text: "adminlog.process_dtm",
        align: "center",
        value: "process_dtm",
        width: "25%",
        sortable: false
      },
      {
        text: "adminlog.detail",
        align: "center",
        value: "detail",
        width: "8%",
        sortable: false
      }],
       dateRules: [
        v => !!v || 'Date is required',
      ],
  }},
    computed: {
    ...mapState(["username", "authorize", "account_active", "service","color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax"
    ]),
    setname(){
      if(this.$t('default') === 'th'){
       return "staff_name_th" }
        else{
        return  "staff_name_en"
        }
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCount: {
      get() {
        let l = this.countdata;
        let s = this.size;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      }
    },
    paginatedData() {
      const start = (this.page - 1) * this.size;
      const end = start + this.size;
      return this.dataprocesslog.slice(start, start + this.size);
    },
    showdatafilter() {
      console.log("seach",this.search);
      if(this.$t('default') === 'th'){
        return this.staff.filter(item => {
        return (
          item.staff_name_th.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      });
      }else{
      return this.staff.filter(item => {
        return (
          item.staff_name_en.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      });
      }
    },

     filteredItems() {
      // console.log(search);
      
      //if (search !== "") {
       // this.rootfile = this.buff;
        let filldata = this.staff.filter(item => {
          return (
            item.staff_name_en.toLowerCase().indexOf(this.search.toLowerCase()) > -1 
          );
        });
        this.staff = [];
        filldata.forEach(filedata => {
          this.staff.push(filedata);
        });
      //} else {
       // this.rootfile = this.buff;
      //}
    },
    headerTable(){
      return 'color:'+this.color.theme+';'+'font-weight: 600; font-size:15px;'
    },headerPage(){
      // console.log(this.color.theme);
      
      return 'color:'+this.color.theme+';'+'font-size: 18px; font-weight: 600;'
    },colorVapp(){
      return 'background:'+this.color.BG+';'
    },colorSort(){
      return 'color:'+this.color.alertText+';'
    },colorProgress(){
      return 'color:'+this.color.theme+';'
    },
    fromdatemin(){
      let today = new Date();
      let setdate = parseInt(today.getDate());
      let setmonth = parseInt((today.getMonth()-2));
      let setyear = today.getFullYear()
      let set2month;
      let set2date;
      if(setdate < 10){
         set2date = '0'+ today.getDate();
      }else{
        set2date =today.getDate();
      }
      if(setmonth < 10){
        set2month = '0'+ (today.getMonth()-2);
      }else{
        set2month =(today.getMonth()-2);
      }
      let filedate = setyear+'-'+set2month+'-'+set2date;
      console.log(filedate);
      return filedate
    }
    
  },
  methods:{
    changeFromdateupload() {
      this.$refs.dialogFromdateupload.save(this.fromdateupload);
      this.todateupload = format(new Date(), "yyyy-MM-dd");
    },
  async downloaddocument(){
      //console.log("เข้า");
      this.loaddataprogress = true
      let str_1 = this.fromdateupload.split("-");
      let fromdate= str_1[0]+str_1[1]+str_1[2];
      let str_2 = this.todateupload.split("-");
      let todate = str_2[0]+str_2[1]+str_2[2];
       var payload = {
        account_id_admin: this.dataAccountId,
        process_type_id: this.data_process.process_type_id,
        process_id: this.selectprocess,
        business_id: this.dataAccountActive.business_info.business_id,
        limit: this.size,
        offset: this.offset,
        name:this.logname,
        from_dtm:fromdate,
        to_dtm:todate,
        download_status:"Y"
      }
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_ADMIN + "/api/download/log_data",
          payload,{headers: { Authorization: auth.code },
           withCredentials: false,
          responseType: "arraybuffer",
          }
        )
        .then(response => {
          console.log("get",response);
            if (response.statusText === "OK") {
              this.loaddataprogress = false
              console.log("get",response.data);
                               if (parseInt(response.headers["content-length"]) < 700 && parseInt(response.headers["content-type"]) === "application/json") {
                  //this.opensnackbar = false;
                  let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
                  Toast.fire({ icon: "error", title: res_error.errorMessage });
                } else {
                 this.loaddataprogress = false
                   const blob = new Blob([response.data]);
                   const content = response.headers["content-type"];
                   saveAs(blob, "รายงานข้อมูลการใช้งาน.xlsx");
                  
                }
                        Toast.fire({
          icon: "success",
          title: "download success",
        });
              
          }else{
            this.loaddataprogress = false
               Toast.fire({
          icon: "error",
          title:result.data.errorCode+":"+result.data.errorMessage ,
        });
        }
        }).catch(error => {
          console.log(error.response);
          this.loaddataprogress = false
          Toast.fire({
            icon: "error",
            title: error
          });
        });
     },
    async getprocess(){
      console.log( this.data_process.process_type_id);
      for(let i=0;i<this.dataprocessname.length;i++){
        console.log(this.dataprocessname[i]["process_id"]);
         if(this.dataprocessname[i]["process_id"] === this.selectprocess){
           console.log(this.dataprocessname[i]["process_id"],this.selectprocess);
            this.selectprocessname = this.dataprocessname[i]["process_name"]
            console.log( this.selectprocessname);
         }
      }

      this.loaddataprogresstable = true;
      this.dataprocesslog=[];
      this.countdata=0;
      console.log("this.fromdateupload",this.fromdateupload);
      let fromdate ="";
      let todate="";
      let str_1;
      let str_2;
      if(this.fromdateupload === "" || this.todateupload === ""){
         Toast.fire({
            icon: "error",
            title: "กรุณากรอกวันที่ต้องการตรวจสอบ"
          });
          this.loaddataprogresstable = false;
      }else{
      if(this.fromdateupload !== null){
       str_1 = this.fromdateupload.split("-");
       fromdate= str_1[0]+str_1[1]+str_1[2];
      }
       if(this.todateupload !== null){
       str_2 = this.todateupload.split("-");
       todate = str_2[0]+str_2[1]+str_2[2];
       }

      console.log(str_1);
      var payload = {
         account_id_admin: this.dataAccountId,
        process_type_id: this.data_process.process_type_id,
        process_id: this.selectprocess,
        business_id: this.dataAccountActive.business_info.business_id,
        limit: this.size,
        offset: this.offset,
        name:this.logname === null ? this.logname = "": this.logname,
        from_dtm:fromdate === "undefinedundefined" ? "" : fromdate,
        to_dtm: todate === "undefinedundefined" ? "" : todate,
        download_status:"N",
        file_folder_name:this.logfilename || "",
      }
      console.log(payload);
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_ADMIN + "/api/get_log_data",
          payload, {headers: { Authorization: auth.code },}
        ) .then(response => {
          console.log(response);
          if (response.data.status === "OK") {
            this.loaddataprogresstable = false;
            console.log(response.data);
            if(this.data_process.process_type_id === "6"){
                 this.check_filemane = false
                console.log(response.data.data);
                for(let i = 0 ;i<response.data.data.length;i++){
                let databuffer = {};
                databuffer["current"] = response.data.data[i]["current_filename"];
                databuffer["current_foldername"] = response.data.data[i]["current_foldername"];
                databuffer["current_status"] = response.data.data[i]["current_status"];
                databuffer["email"] = response.data.data[i]["email"];
                databuffer["name_th"] = response.data.data[i]["name_th"]; 
                databuffer["name_en"] = response.data.data[i]["name_en"]; 
                databuffer["new_filename"] = response.data.data[i]["new_filename"];  
                databuffer["new_filestatus"] = response.data.data[i]["new_filestatus"];  
                databuffer["new_foldername"] = response.data.data[i]["new_foldername"];   
                databuffer["old_filename"] = response.data.data[i]["old_filename"];
                databuffer["old_filestatus"] = response.data.data[i]["old_filestatus"];  
                databuffer["old_foldername"] = response.data.data[i]["old_foldername"];  
                databuffer["process_dtm"] = response.data.data[i]["process_dtm"];  
                databuffer["process_name"] = response.data.data[i]["process_name"]; 
                databuffer["process_name_eng"] = response.data.data[i]["process_name_eng"]; 
                databuffer["process_type"] = "6";
                this.countdata = response.data["count"]; 
                this.dataprocesslog.push(databuffer);
                }
              }
            else if(this.data_process.process_type_id === "5"){
               if(this.selectprocess === "47ca545e0b0326ef7015c2736a8cf4cc"){
                 this.check_filemane = true
              for(let i = 0 ;i<response.data.data.length;i++){
                console.log(response.data.data);
                let databuffer = {}
                databuffer["business_th_setting"] = response.data.data[i]["business_th_setting"];
                databuffer["business_eng_setting"] = response.data.data[i]["business_eng_setting"];
                databuffer["department_name"] = response.data.data[i]["department_name"];
                databuffer["name_th"] = response.data.data[i]["name_th"];
                databuffer["name_en"] = response.data.data[i]["name_en"]; 
                databuffer["name_th_setting"] = response.data.data[i]["name_th_setting"];
                databuffer["name_en_setting"] = response.data.data[i]["name_en_setting"];
                databuffer["process_dtm"] = response.data.data[i]["process_dtm"];
                databuffer["process_name"] = response.data.data[i]["process_name"];
                databuffer["process_name_eng"] = response.data.data[i]["process_name_eng"];
                //databuffer["result_setting"] = response.data.data[i]["result_setting"];
                databuffer["permission_status"] = response.data.data[i]["permission_status"];
                databuffer["clone_data"] = response.data.data[i]["clone_data"];
                databuffer["process_type"] = "5";
                databuffer["process_id"] = "47ca545e0b0326ef7015c2736a8cf4cc";
                databuffer["data_name"] = response.data.data[i]["data_name"];
                databuffer["data_type"] = response.data.data[i]["data_type"];
                databuffer["permission_type"] = response.data.data[i]["permission_type"];
                databuffer["process_name"] = response.data.data[i]["process_name"];
                databuffer["process_name_eng"] = response.data.data[i]["process_name_eng"];
                databuffer["old_data_permission"] = response.data.data[i]["old_data_permission"];
                this.countdata = response.data["count"];
                this.dataprocesslog.push(databuffer);
              }
               }else{
                 this.check_filemane = true
              for(let i = 0 ;i<response.data.data.length;i++){
                console.log(response.data.data);
                let databuffer = {}
                databuffer["business_th_setting"] = response.data.data[i]["business_th_setting"];
                databuffer["department_name"] = response.data.data[i]["department_name"];
                databuffer["name_th"] = response.data.data[i]["name_th"];
                databuffer["name_en"] = response.data.data[i]["name_en"]; 
                databuffer["name_th_setting"] = response.data.data[i]["name_th_setting"];
                databuffer["name_en_setting"] = response.data.data[i]["name_en_setting"];
                databuffer["process_dtm"] = response.data.data[i]["process_dtm"];
                databuffer["process_name"] = response.data.data[i]["process_name"];
                databuffer["process_name_eng"] = response.data.data[i]["process_name_eng"];
                databuffer["result_setting"] = response.data.data[i]["result_setting"];
                //databuffer["permission_status"] = response.data.data[i]["permission_status"];
                //databuffer["clone_data"] = response.data.data[i]["clone_data"];
                databuffer["process_type"] = "5";
                 databuffer["process_id"] = "other";
                this.countdata = response.data["count"];
                this.dataprocesslog.push(databuffer);
               }
              
              }            
              }else if(this.data_process.process_type_id === "4"){
                this.check_filemane = true
                for(let i = 0 ;i<response.data.data.length;i++){
                  let databuffer = {}                 
                  databuffer["process_dtm"] = response.data.data[i]["process_dtm"];
                  databuffer["process_name"] = response.data.data[i]["process_name"];
                  databuffer["process_name_eng"] = response.data.data[i]["process_name_eng"];
                  databuffer["name_en"] = response.data.data[i]["name_en"]; 
                  databuffer["name_th"] = response.data.data[i]["name_th"];  
                  databuffer["process_type"] = "4";  
                  this.countdata = response.data["count"];         
                  this.dataprocesslog.push(databuffer);
                }
              }
              else if(this.data_process.process_type_id === "3"){
                this.check_filemane = true
                console.log(response.data.data);
                for(let i = 0 ;i<response.data.data.length;i++){
                let databuffer = {};
                databuffer["current"] = response.data.data[i]["current_name"];
                databuffer["current_status_share_link"] = response.data.data[i]["current_status_share_link"];
                databuffer["name"] = response.data.data[i]["name"];
                databuffer["name_en"] = response.data.data[i]["name_en"];  
                databuffer["name_th"] = response.data.data[i]["name_th"];  
                databuffer["new_name"] = response.data.data[i]["new_name"];  
                databuffer["old_name"] = response.data.data[i]["old_name"];   
                databuffer["process_dtm"] = response.data.data[i]["process_dtm"];  
                databuffer["process_name"] = response.data.data[i]["process_name"]; 
                databuffer["process_name_eng"] = response.data.data[i]["process_name_eng"];  
                databuffer["share_with"] = response.data.data[i]["share_with"];  
                databuffer["status_share"] = response.data.data[i]["status_share"]; 
                databuffer["data_type"] = response.data.data[i]["data_type"];  
                databuffer["process"] = response.data.data[i]["process"];  
                databuffer["process_type"] = "3";
                this.countdata = response.data["count"];
                this.dataprocesslog.push(databuffer);
                }
              }
              else if(this.data_process.process_type_id === "2"){
                this.check_filemane = false
                console.log(response.data.data);
                for(let i = 0 ;i<response.data.data.length;i++){
                let databuffer = {};
                databuffer["filename_inbox"] = response.data.data[i]["filename_inbox"];
                databuffer["filename_outbox"] = response.data.data[i]["filename_outbox"];
                databuffer["name_en"] = response.data.data[i]["name_en"]; 
                databuffer["name_th"] = response.data.data[i]["name_th"];  
                databuffer["new_relation"] = response.data.data[i]["new_relation"];  
                databuffer["old_relation"] = response.data.data[i]["old_relation"];   
                databuffer["process_dtm"] = response.data.data[i]["process_dtm"];  
                databuffer["process_name"] = response.data.data[i]["process_name"]; 
                databuffer["process_name_eng"] = response.data.data[i]["process_name_eng"]; 
                databuffer["process_type"] = "2"; 
                this.countdata = response.data["count"];
                this.dataprocesslog.push(databuffer);
                }
              }
              else if(this.data_process.process_type_id === "1"){
                this.check_filemane = false
                console.log(response.data.data);
                for(let i = 0 ;i<response.data.data.length;i++){
                let databuffer = {};
                databuffer["current"] = response.data.data[i]["current_foldername"];
                databuffer["current_status"] = response.data.data[i]["current_status"];
                databuffer["name_th"] = response.data.data[i]["name_th"]; 
                databuffer["name_en"] = response.data.data[i]["name_en"]; 
                databuffer["new_foldername"] = response.data.data[i]["new_foldername"];  
                databuffer["new_folderstatus"] = response.data.data[i]["new_folderstatus"];  
                databuffer["new_parent_folder_name"] = response.data.data[i]["new_parent_folder_name"];   
                databuffer["old_foldername"] = response.data.data[i]["old_foldername"];  
                databuffer["old_parent_folder_name"] = response.data.data[i]["old_parent_folder_name"];  
                databuffer["process_dtm"] = response.data.data[i]["process_dtm"];  
                databuffer["process_name"] = response.data.data[i]["process_name"]; 
                databuffer["process_name_eng"] = response.data.data[i]["process_name_eng"]; 
                databuffer["process_type"] = "1"; 
                this.countdata = response.data["count"];
                this.dataprocesslog.push(databuffer);
                }
              }
               else if(this.data_process.process_type_id === "0"){
                 this.check_filemane = false
                console.log(response.data.data);
                for(let i = 0 ;i<response.data.data.length;i++){
                let databuffer = {};
                databuffer["current"] = response.data.data[i]["current_filename"];
                databuffer["current_foldername"] = response.data.data[i]["current_foldername"];
                databuffer["current_status"] = response.data.data[i]["current_status"];
                databuffer["name_th"] = response.data.data[i]["name_th"]; 
                databuffer["name_en"] = response.data.data[i]["name_en"]; 
                databuffer["new_filename"] = response.data.data[i]["new_filename"];  
                databuffer["new_filestatus"] = response.data.data[i]["new_filestatus"];  
                databuffer["new_foldername"] = response.data.data[i]["new_foldername"];   
                databuffer["old_filename"] = response.data.data[i]["old_filename"];
                databuffer["old_filestatus"] = response.data.data[i]["old_filestatus"];  
                databuffer["old_foldername"] = response.data.data[i]["old_foldername"];  
                databuffer["process_dtm"] = response.data.data[i]["process_dtm"];  
                databuffer["process_name"] = response.data.data[i]["process_name"]; 
                databuffer["process_name_eng"] = response.data.data[i]["process_name_eng"]; 
                databuffer["process_type"] = "0";
                this.countdata = response.data["count"]; 
                this.dataprocesslog.push(databuffer);
                }
              }
            console.log(this.dataprocesslog);
            
            }else if(response.data.errorCode === "ER103"){
              this.loaddataprogresstable = false;
              Toast.fire({
            icon: "error",
            title: "กรุณาเลือกข้อมูลการทำงานที่ต้องการตรวจสอบ"
          });
            } else{
               this.loaddataprogresstable = false;
              Toast.fire({
            icon: "error",
            title: response.data.errorCode + ': ' + response.data.errorMessage
          });
            }
        }).catch(error => {
          console.log(error.response);
          this.loaddataprogress = false;
          this.loaddataprogresstable = false;
          Toast.fire({
            icon: "error",
            title: error
          });
        });
      }
    },
    async searchdocument(){
       this.loaddataprogresstable = true;
      this.dataprocesslog=[];
      this.countdata=0;
      console.log(this.logname,this.fromdateupload,this.todateupload,this.selectdata,this.selectprocess);
      let str_1 = this.fromdateupload.split("-");
      let fromdate= str_1[0]+str_1[1]+str_1[2];
      let str_2 = this.todateupload.split("-");
      let todate = str_2[0]+str_2[1]+str_2[2];
      console.log(str_1);
       var payload = {
        account_id_admin: this.dataAccountId,
        process_type_id: this.data_process.process_type_id,
        process_id: this.selectprocess,
        business_id: this.dataAccountActive.business_info.business_id,
        limit: this.size,
        offset: this.offset,
        name:this.logname,
        from_dtm:fromdate === "undefinedundefined" ? "" : fromdate,
        to_dtm: todate === "undefinedundefined" ? "" : todate,
        download_status:"N"
      }
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_ADMIN + "/api/get_log_data",
          payload,{headers: { Authorization: auth.code },}
        )
        .then(response => {
          console.log("get",response);
                     if (response.data.status === "OK") {
            this.loaddataprogresstable = false;
            console.log(response.data);
            if(this.data_process.process_type_id === "6"){
                 this.check_filemane = false
                console.log(response.data.data);
                for(let i = 0 ;i<response.data.data.length;i++){
                let databuffer = {};
                databuffer["current"] = response.data.data[i]["current_filename"];
                databuffer["current_foldername"] = response.data.data[i]["current_foldername"];
                databuffer["current_status"] = response.data.data[i]["current_status"];
                databuffer["email"] = response.data.data[i]["email"];
                databuffer["name_th"] = response.data.data[i]["name_th"]; 
                databuffer["name_en"] = response.data.data[i]["name_en"]; 
                databuffer["new_filename"] = response.data.data[i]["new_filename"];  
                databuffer["new_filestatus"] = response.data.data[i]["new_filestatus"];  
                databuffer["new_foldername"] = response.data.data[i]["new_foldername"];   
                databuffer["old_filename"] = response.data.data[i]["old_filename"];
                databuffer["old_filestatus"] = response.data.data[i]["old_filestatus"];  
                databuffer["old_foldername"] = response.data.data[i]["old_foldername"];  
                databuffer["process_dtm"] = response.data.data[i]["process_dtm"];  
                databuffer["process_name"] = response.data.data[i]["process_name"]; 
                databuffer["process_name_eng"] = response.data.data[i]["process_name_eng"]; 
                databuffer["process_type"] = "6";
                this.countdata = response.data["count"]; 
                this.dataprocesslog.push(databuffer);
                }
              }
            else if(this.data_process.process_type_id === "5"){
              for(let i = 0 ;i<response.data.data.length;i++){
                console.log(response.data.data);
                let databuffer = {}
                databuffer["business_th_setting"] = response.data.data[i]["business_th_setting"];
                databuffer["department_name"] = response.data.data[i]["department_name"];
                databuffer["name_th"] = response.data.data[i]["name_th"];
                databuffer["name_en"] = response.data.data[i]["name_en"]; 
                databuffer["name_th_setting"] = response.data.data[i]["name_th_setting"];
                databuffer["process_dtm"] = response.data.data[i]["process_dtm"];
                databuffer["process_name"] = response.data.data[i]["process_name"];
                databuffer["result_setting"] = response.data.data[i]["result_setting"];
                databuffer["old_data_permission"] = response.data.data[i]["old_data_permission"];
                databuffer["process_type"] = "5";
                this.countdata = response.data["count"];
                this.dataprocesslog.push(databuffer);
              }            
              }else if(this.data_process.process_type_id === "4"){
                for(let i = 0 ;i<response.data.data.length;i++){
                  let databuffer = {}                 
                  databuffer["process_dtm"] = response.data.data[i]["process_dtm"];
                  databuffer["process_name"] = response.data.data[i]["process_name"];
                  databuffer["name_en"] = response.data.data[i]["name_en"]; 
                  databuffer["name_th"] = response.data.data[i]["name_th"];  
                  databuffer["process_type"] = "4";  
                  this.countdata = response.data["count"];         
                  this.dataprocesslog.push(databuffer);
                }
              }
              else if(this.data_process.process_type_id === "3"){
                console.log(response.data.data);
                for(let i = 0 ;i<response.data.data.length;i++){
                let databuffer = {};
                databuffer["current"] = response.data.data[i]["current_name"];
                databuffer["current_status_share_link"] = response.data.data[i]["current_status_share_link"];
                databuffer["name"] = response.data.data[i]["name"];
                databuffer["name_en"] = response.data.data[i]["name_en"];  
                databuffer["name_th"] = response.data.data[i]["name_th"];  
                databuffer["new_name"] = response.data.data[i]["new_name"];  
                databuffer["old_name"] = response.data.data[i]["old_name"];   
                databuffer["process_dtm"] = response.data.data[i]["process_dtm"];  
                databuffer["process_name"] = response.data.data[i]["process_name"];  
                databuffer["share_with"] = response.data.data[i]["share_with"];  
                databuffer["status_share"] = response.data.data[i]["status_share"];  
                databuffer["process_type"] = "3";
                this.countdata = response.data["count"];
                this.dataprocesslog.push(databuffer);
                }
              }
              else if(this.data_process.process_type_id === "2"){
                console.log(response.data.data);
                for(let i = 0 ;i<response.data.data.length;i++){
                let databuffer = {};
                databuffer["filename_inbox"] = response.data.data[i]["filename_inbox"];
                databuffer["filename_outbox"] = response.data.data[i]["filename_outbox"];
                databuffer["name_en"] = response.data.data[i]["name_en"]; 
                databuffer["name_th"] = response.data.data[i]["name_th"];  
                databuffer["new_relation"] = response.data.data[i]["new_relation"];  
                databuffer["old_relation"] = response.data.data[i]["old_relation"];   
                databuffer["process_dtm"] = response.data.data[i]["process_dtm"];  
                databuffer["process_name"] = response.data.data[i]["process_name"];  
                databuffer["process_type"] = "2"; 
                this.countdata = response.data["count"];
                this.dataprocesslog.push(databuffer);
                }
              }
              else if(this.data_process.process_type_id === "1"){
                console.log(response.data.data);
                for(let i = 0 ;i<response.data.data.length;i++){
                let databuffer = {};
                databuffer["current"] = response.data.data[i]["current_foldername"];
                databuffer["current_status"] = response.data.data[i]["current_status"];
                databuffer["name_th"] = response.data.data[i]["name_th"]; 
                databuffer["name_en"] = response.data.data[i]["name_en"]; 
                databuffer["new_foldername"] = response.data.data[i]["new_foldername"];  
                databuffer["new_folderstatus"] = response.data.data[i]["new_folderstatus"];  
                databuffer["new_parent_folder_name"] = response.data.data[i]["new_parent_folder_name"];   
                databuffer["old_foldername"] = response.data.data[i]["old_foldername"];  
                databuffer["old_parent_folder_name"] = response.data.data[i]["old_parent_folder_name"];  
                databuffer["process_dtm"] = response.data.data[i]["process_dtm"];  
                databuffer["process_name"] = response.data.data[i]["process_name"]; 
                databuffer["process_type"] = "1"; 
                this.countdata = response.data["count"];
                this.dataprocesslog.push(databuffer);
                }
              }
               else if(this.data_process.process_type_id === "0"){
                console.log(response.data.data);
                for(let i = 0 ;i<response.data.data.length;i++){
                let databuffer = {};
                databuffer["current"] = response.data.data[i]["current_filename"];
                databuffer["current_foldername"] = response.data.data[i]["current_foldername"];
                databuffer["current_status"] = response.data.data[i]["current_status"];
                databuffer["name_th"] = response.data.data[i]["name_th"]; 
                databuffer["name_en"] = response.data.data[i]["name_en"]; 
                databuffer["new_filename"] = response.data.data[i]["new_filename"];  
                databuffer["new_filestatus"] = response.data.data[i]["new_filestatus"];  
                databuffer["new_foldername"] = response.data.data[i]["new_foldername"];   
                databuffer["old_filename"] = response.data.data[i]["old_filename"];
                databuffer["old_filestatus"] = response.data.data[i]["old_filestatus"];  
                databuffer["old_foldername"] = response.data.data[i]["old_foldername"];  
                databuffer["process_dtm"] = response.data.data[i]["process_dtm"];  
                databuffer["process_name"] = response.data.data[i]["process_name"]; 
                databuffer["process_type"] = "0";
                this.countdata = response.data["count"]; 
                this.dataprocesslog.push(databuffer);
                }
              }
            console.log(this.dataprocesslog);
            
            }else{
              Toast.fire({
            icon: "error",
            title: response.data.errorCode + ': ' + response.data.errorMessage
          });
            }
        }).catch(error => {
          console.log(error.response);
          this.loaddataprogress = false;
          this.loaddataprogresstable = false;
          Toast.fire({
            icon: "error",
            title: error
          });
        });
     },
    clickpagination(){
      this.offset = (this.page - 1) * this.size;
      console.log(this.offset);
      console.log(this.page);
      console.log(this.size);
      
      this.getprocess();
    },
    async getprocessname(){
      this.selectprocess={};
      var payload = {
         account_id_admin: this.dataAccountId
      };
      let auth = await gbfGenerate.generateToken();
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_ADMIN + "/api/get_process_name",
          payload,{headers: { Authorization: auth.code },}
        )
        .then(response => {
          console.log("get",response);
            if (response.data.status === "OK") {
              console.log("get1",response.data.key_data);
              this.key_data = response.data.key_data;
              this.bufferprocess = response.data.data
            }else{
              this.loaddataprogresstable = false;
              Toast.fire({
            icon: "error",
            title: response.data.errorCode + ': ' + response.data.errorMessage
          });
            }
        }).catch(error => {
          console.log(error.response);
          this.loaddataprogress = false;
          this.loaddataprogresstable = false;
          Toast.fire({
            icon: "error",
            title: error
          });
        });
    },
    getdataprocess(){     
      console.log("this.bufferprocess",this.bufferprocess);
      for(let i = 0 ; i< this.bufferprocess.length;i++){       
       
          for(let j = 0;j<this.bufferprocess[i].process_name.length;j++){
            if(this.bufferprocess[i].process_type === this.selectdata){
               console.log(this.bufferprocess[i].process_type_id);
              if(this.bufferprocess[i].process_type_id === "0" ||this.bufferprocess[i].process_type_id === "1" || this.bufferprocess[i].process_type_id === "2"){
                  
                  this.check_filemane = false
              }else{
                  this.check_filemane = true
              }
            //console.log(this.bufferprocess[i]);          
            this.dataprocessname = this.bufferprocess[i]["process_name"];
            this.data_process = this.bufferprocess[i];
            this.selectprocess="all";
            
            console.log(this.dataprocessname);
          }
          
        }
      
      }
     // this.getprocess();
      console.log("data",this.data_process);
      
    },

    formatdatetime(_datetime) {
      // console.log(this.rootfile);
      if (_datetime === "" || _datetime === "-" || _datetime === undefined) {
        return "-";
      } else {
        let dateyear =
          _datetime.split("")[0] +
          _datetime.split("")[1] +
          _datetime.split("")[2] +
          _datetime.split("")[3];
        let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
        let dateday = _datetime.split("")[6] + _datetime.split("")[7];
        let hour = _datetime.split("")[8] + _datetime.split("")[9];
        let minute = _datetime.split("")[10] + _datetime.split("")[11];
        let second = _datetime.split("")[12] + _datetime.split("")[13];

        return (
          dateday +
          "/" +
          datemonth +
          "/" +
          dateyear +
          " " +
          hour +
          ":" +
          minute +
          ":" +
          second
        );
      }
    },
  },
  mounted() {
    this.getprocessname();
    console.log(this.opendetaillog);
    this.loaddataprogress = false;
    
  },
  created() {
    //วันปัจจุบันจัด format แล้ว
    this.date_now = format(new Date(), "yyyy-MM-dd")

    //วันปัจจุบันยังไม่จัด format เอาไว้คำนวณ
    this.date_now_noformat = new Date()

    //วันที่ก่อนวันปัจจุบัน 90 วัน
    this.date_back = format(new Date(new Date().setDate(this.date_now_noformat.getDate() - 90)), "yyyy-MM-dd")
  }
}
</script>
<style>
#thiscontainerlogadmin {
  height: calc(101vh - 180px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;

}
</style>